/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Profile', route: { name: 'user-profile' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Daily Parking', route: { name: 'daily-parking' }, icon: 'CreditCardIcon', isBookmarked: false },
    
    ],
  },
  files: {
    key: 'file_name',
    data: [
      {
        file_name: 'Permit',
        from: 'SBP Module',
        icon: require('@/assets/images/icons/pdf.png'),
        size: '5.3 mb',
      },
    ],
  },
  contacts: {
    key: 'name',
    data: [
      {
        img: require('@/assets/images/avatars/turkana.png'),
        name: 'County HQ',
        email: 'revenue@turkana.go.ke',
        time: '12/09/2021',
      },
      {
        img: require('@/assets/images/avatars/user-icon.png'),
        name: 'Stanley Nguma',
        email: 'contactnguma@gmail.com',
        time: '11/11/2019',
      },
      {
        img: require('@/assets/images/avatars/gok.png'),
        name: 'Police',
        email: 'turkanaocpd@gmail.com',
        time: '11/11/2019',
      },
    ],
  },
}
/* eslint-enable */
